import React from "react";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon, fab, faGithub, faGoogle, faLinkedin } from "./icons";

function Navigation(props) {
  return (
    <div className="navigation">
      <nav class="navbar navbar-expand navbar-dark bg-darker">
        <div class="container navbar-container">

          <div class="">
            <Link class="navbar-brand center-header-group" to="/">
              Joey Bowser | Software Engineer
            </Link>
            <ul class="navbar-nav center-header-group">
              <li
                class={`nav-item  ${
                  props.location.pathname === "/about" ? "active" : ""
                }`}
              >
                <Link class="nav-link" to="/about">
                  About
                </Link>
              </li>
              <li
                class={`nav-item  ${
                  props.location.pathname === "/projects" ? "active" : ""
                }`}
              >
                <Link class="nav-link" to="/projects">
                  Projects
                </Link>
              </li>
              <li
                class={`nav-item  ${
                  props.location.pathname === "/contact" ? "active" : ""
                }`}
              >
                <Link class="nav-link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div class="header-icons">
            <a href="mailto:joey.l.bowser@gmail.com"><FontAwesomeIcon icon={fab, faGoogle} size="lg" /></a>
            <a href="https://www.linkedin.com/in/joey-bowser
" target="_blank"><FontAwesomeIcon icon={fab, faLinkedin} size="lg" /></a>
            <a href="https://github.com/JoeyBowser
" target="_blank"><FontAwesomeIcon icon={fab, faGithub} size="lg" /></a>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default withRouter(Navigation);