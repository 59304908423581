import React from "react";
import { Text, Link } from "./blocks";
import './styles.css';
import data from "./data/data.json";

function Contact() {
  return (
    <div className="contact">
      { data.map(thisData => (
        <React.Fragment>
          <div class="content-container" styles='padding: 0 5%'>
            <Text class_names="" name={thisData.contact.header} tag_name="h1"/>
            <Text class_names="inline" name={thisData.contact.text} tag_name="p"/>
            <Link class_names="" name={thisData.contact.link1} url="mailto:joey.l.bowser@gmail.com"/>
            <Text class_names="inline" name={thisData.contact.text2} tag_name="p"/>
            <Link class_names="" name={thisData.contact.link2} url="https://www.linkedin.com/in/joey-bowser" newWindow="true"/>
            <Text class_names="inline" name={thisData.contact.text3} tag_name="p"/>
            <Link class_names="" name={thisData.contact.link3} url="http://www.joeybowser.com/JoeyBowserResume.pdf" newWindow="true"/>
            <Text class_names="inline" name={thisData.contact.text4} tag_name="p"/>
          </div>
        </React.Fragment>
      )) }
    </div>
  );
}

export default Contact;