import React from "react";
import { Text, Link } from "./blocks";
import './styles.css';
import data from "./data/data.json";

function Projects() {
  return (
    <div className="projects">
      { data.map(thisData => (
        <React.Fragment>
          <div class="content-container" styles='padding: 0 5%'>
            <Text class_names="" name={thisData.projects.header} tag_name="h1"/>
            <Text class_names="" name={thisData.projects.header2} tag_name="h3"/>
            <Text class_names="" name={thisData.projects.text} tag_name="p"/>
            <Link class_names="" name={thisData.projects.link} url="/firesmokesim.html" newWindow="true"/>
            <Text class_names="" name={thisData.projects.text2} tag_name="p"/>
            <Link class_names="" name={thisData.projects.link2} url="/objviewersim.html" newWindow="true"/>
            <Text class_names="" name={thisData.projects.text3} tag_name="p"/>
            <Link class_names="" name={thisData.projects.link3} url="/3dmodelviewer.html" newWindow="true"/>
            <Text class_names="" name={thisData.projects.text4} tag_name="p"/>
            <Link class_names="" name={thisData.projects.link4} url="deliverysim.html" newWindow="true"/>
            <Text class_names="" name={thisData.projects.text5} tag_name="p"/>
          </div>
        </React.Fragment>
      )) }
    </div>
  );
}

export default Projects;