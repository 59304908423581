import React from "react";

const Link = (props) => {
  const styles = {
    exampleStyle: {
      padding: props.padding
    }
  };
  let newWindow = props.newWindow === true || props.newWindow === 'true' ? '_blank' : '';

  return (
    <a className={props.class_names} style={styles.exampleStyle} href={props.url} target={newWindow}>{props.name}
    </a>
  );
};

export default Link;