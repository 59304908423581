import React from "react";
import { Text, Link, Image } from "./blocks";
import './styles.css';
import data from "./data/data.json";

function About() {
  return (
    <div className="about">
      { data.map(thisData => (
        <React.Fragment>
          <div class="content-container" styles='padding: 0 5%'>
            <Text class_names="" name={thisData.about.header} tag_name="h1"/>
            <div className="fifty-fifty">
              <Text class_names="" name={thisData.about.text} tag_name="p"/>
              <Text class_names="" name={thisData.about.text2} tag_name="p"/>
              <Text class_names="" name={thisData.about.text3} tag_name="p"/>
              <Text class_names="inline" name={thisData.about.text4} tag_name="p"/>
              <Link class_names="" name={thisData.about.text5} url="./JoeyBowserResume.pdf" newWindow="true"/>
              <Text name="."/>
            </div>
            <Image class_names="fifty-fifty" src="jbowser_bio_pic.JPG" alt_text={thisData.about.image.text}/>
          </div>
        </React.Fragment>
      )) }
    </div>
  );
}

export default About;