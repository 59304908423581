import React from "react";

const Image = (props) => {
  const styles = {
    exampleStyle: {
      padding: props.padding
    }
  };

  return (
    <div className={props.class_names} style={styles.exampleStyle}>
      <img 
        // class="img-fluid rounded mb-4 mb-lg-0"
        className={"img-fluid mb-4 mb-lg-0"}
        src={"./images/" + props.src}
        alt={props.alt_text}
      />
    </div>
  );
};

export default Image;