import React from "react";
import { Text, Link } from "./blocks";
import './styles.css';
import data from "./data/data.json";
import { FontAwesomeIcon, fab, faGithub, faGoogle, faLinkedin } from "./icons";

function Home() {
  return (
    <div className="home bg-darker home-container">
      { data.map(thisData => (
        <React.Fragment>
          <div class="content-container" styles=''>
            <Text class_names="" name={thisData.home.header} tag_name="h1"/>
            <Link class_names="header-link" name={thisData.home.link} url="/about"/>
            <div class="header-icons">
              <a href="mailto:joey.l.bowser@gmail.com"><FontAwesomeIcon icon={fab, faGoogle} size="lg" /></a>
              <a href="https://www.linkedin.com/in/joey-bowser
  " target="_blank"><FontAwesomeIcon icon={fab, faLinkedin} size="lg" /></a>
              <a href="https://github.com/JoeyBowser
  " target="_blank"><FontAwesomeIcon icon={fab, faGithub} size="lg" /></a>
            </div>
          </div>
        </React.Fragment>
      )) }
    </div>
  );
}

export default Home;