import React from "react";

const Text = (props) => {
  const styles = {
    exampleStyle: {
      padding: props.padding
    }
  };
  const TagName = `${props.tag_name}`;

  return (
    <TagName className={props.class_names} style={styles.exampleStyle}>{props.name}
    </TagName>
  );
};

export default Text;