import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navigation, Home, About, Projects, Contact } from "./components";
import { useLocation } from 'react-router-dom';

function Navbar() {
  const location = useLocation();
  return (
    <div className="App">
      { location.pathname !== '/' ? <Navigation /> : null }
    </div>
  );
}

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/" exact component={() => <Home />} />
        <Route path="/about" exact component={() => <About />} />
        <Route path="/projects" exact component={() => <Projects />} />
        <Route path="/contact" exact component={() => <Contact />} />
      </Switch>
    </Router>
  )
}

export default App;